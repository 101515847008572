import React, { Component, Suspense } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

/*------ Pages-----*/
import ScrollToTopRoute from "./ScrollToTopRoute";
//import LoadingSpinner from "./SpecialComponents/LoadingSpinner";
const homeERP = React.lazy(() => import("./Pages/Home-ERP"));
const homeERP2 = React.lazy(() => import("./Pages/Home-ERP2"));
const About = React.lazy(() => import("./Pages/About"));
const Service = React.lazy(() => import("./Pages/Service"));
const Price = React.lazy(() => import("./Pages/Price"));
const Faq = React.lazy(() => import("./Pages/Faq"));
const Contact = React.lazy(() => import("./Pages/Contact"));
const DemoRequests = React.lazy(() => import("./Pages/DemoRequests"));
const BuyRequests = React.lazy(() => import("./Pages/BuyRequests"));
const NotFound = React.lazy(() => import("./Pages/404"));

const RandevuTakibi = React.lazy(() => import("./Pages/ServicePages/RandevuTakibi"));
const GelirGiderTakibi = React.lazy(() => import("./Pages/ServicePages/GelirGiderTakibi"));
const ZamandanTasarruf = React.lazy(() => import("./Pages/ServicePages/ZamandanTasarruf"));
const VeriGuvenligi = React.lazy(() => import("./Pages/ServicePages/VeriGuvenligi"));
const EsnekYetkilendirme = React.lazy(() => import("./Pages/ServicePages/EsnekYetkilendirme"));
const DestekSistemi = React.lazy(() => import("./Pages/ServicePages/DestekSistemi"));
const KurulumGerektirmez = React.lazy(() => import("./Pages/ServicePages/KurulumGerektirmez"));
const KullanıcıDostuArayuz = React.lazy(() => import("./Pages/ServicePages/KullanıcıDostuArayuz"));
const VersiyonGuncelleme = React.lazy(() => import("./Pages/ServicePages/VersiyonGuncelleme"));
const SmsEntegrasyonu = React.lazy(() => import("./Pages/ServicePages/SmsEntegrasyonu"));
const EpostaEntegrasyonu = React.lazy(() => import("./Pages/ServicePages/EpostaEntegrasyonu"));
const SubeYonetimi = React.lazy(() => import("./Pages/ServicePages/SubeYonetimi"));
const CokluDilSecenegi = React.lazy(() => import("./Pages/ServicePages/CokluDilSecenegi"));
const GuvenliErisim = React.lazy(() => import("./Pages/ServicePages/GuvenliErisim"));
const IletisimFormu = React.lazy(() => import("./Pages/ServicePages/IletisimFormu"));
const MusteriFiltreleme = React.lazy(() => import("./Pages/ServicePages/MusteriFiltreleme"));
const MusteriMemnuniyetAnketi = React.lazy(() => import("./Pages/ServicePages/MusteriMemnuniyetAnketi"));
const SosyalMedyaEntegrasyonu = React.lazy(() => import("./Pages/ServicePages/SosyalMedyaEntegrasyonu"));
const UrunTakibi = React.lazy(() => import("./Pages/ServicePages/UrunTakibi"));
const PersonelPerformansRaporlama = React.lazy(() => import("./Pages/ServicePages/PersonelPerformansRaporlama"));
const Efatura = React.lazy(() => import("./Pages/ServicePages/Efatura"));
const ParaPuan = React.lazy(() => import("./Pages/ServicePages/ParaPuan"));
const VeriAktarimi = React.lazy(() => import("./Pages/ServicePages/VeriAktarimi"));
const SeansliSatis = React.lazy(() => import("./Pages/ServicePages/SeansliSatis"));
const KVKK = React.lazy(() => import("./Pages/KVKK"));
const AcikRizaMetni = React.lazy(() => import("./Pages/AcikRizaMetni"));

//const BlogGrid = React.lazy(() => import("./Pages/BlogGridPage"));
const BlogList = React.lazy(() => import("./Pages/Bloglist"));

const SalonUretkenligi = React.lazy(() => import("./Pages/BlogPages/SalonUretkenligi"));




//import { Home } from "./Pages/Home";
//import DigitalMarketing from "./Pages/Digital-marketing";
//import PaymentProcessing from "./Pages/Payment-processing";
//import HRManagement from "./Pages/HR-Management";
//import Startup from "./Pages/Startup";
//import HomeCRM from "./Pages/Home-CRM";
//import Process from "./Pages/Process";
//import Team from "./Pages/Team";
//import Portfolio2col from "./Pages/Portfolio-2col";
//import Portfolio3col from "./Pages/Portfolio-3col";
//import Portfoliofull4col from "./Pages/Portfolio-fullwidth-4col";
//import PortfolioSingle from "./Pages/PortfolioSingle";
//import Bloglist from "./Pages/Bloglist";
//import BlogSingle from "./Pages/BlogSingle";
//import Landing from "./Pages/Landing";
//import homesupport from "./Pages/home-support";
//import homeHosting from "./Pages/HomeHosting";
//import homeSecurity from "./Pages/HomeSecurity";
//import homeSoftwareDark from "./Pages/Home-software-dark";
//import HomeAppShowcase from "./Pages/Home-app-showcase";
//import HomeCloud from "./Pages/Home-cloud";
//import HomeTracking from "./Pages/Home-tracking";
//import HomeEvent from "./Pages/Home-event";
//import HomeChat from "./Pages/Home-chat";
//import ServiceDetails from "./Pages/ServiceDetails";
//import SignIn from "./Pages/SignIn";
//import SignUp from "./Pages/SignUp";
//import BlogGridPage from "./Pages/BlogGridPage";
//import MessengerCustomerChat from 'react-messenger-customer-chat';

class App extends Component {
  componentDidMount() {
    this.props.hideLoader();
    if (document.querySelector("#root > div > div:nth-child(1)")) {
      document.querySelector("#root > div > div:nth-child(1)").style.display = 'inline';
    }
  }
  render() {
    return (
      <>
        <Router>
          <Suspense fallback={<div id="preloader">
            <div id="ctn-preloader" class="ctn-preloader">
              <div class="animation-preloader">
                <div class="spinner"></div>
                <div class="txt-loading">
                  <span data-text-preloader="B" class="letters-loading">
                    B
                  </span>
                  <span data-text-preloader="E" class="letters-loading">
                    E
                  </span>
                  <span data-text-preloader="A" class="letters-loading">
                    A
                  </span>
                  <span data-text-preloader="U" class="letters-loading">
                    U
                  </span>
                  <span data-text-preloader="T" class="letters-loading">
                    T
                  </span>
                  <span data-text-preloader="Y" class="letters-loading">
                    Y
                  </span>
                  <span data-text-preloader="W" class="letters-loading">
                    W
                  </span>
                  <span data-text-preloader="A" class="letters-loading">
                    A
                  </span>
                  <span data-text-preloader="R" class="letters-loading">
                    R
                  </span>
                  <span data-text-preloader="E" class="letters-loading">
                    E
                  </span>
                </div>
              </div>
            </div>
          </div>}>
            <Switch>
              <ScrollToTopRoute exact={true} path={"/"} component={homeERP} />
              <ScrollToTopRoute path="/guzellik-merkezi-yazilimi" component={homeERP} />
              <ScrollToTopRoute path="/guzellik-salonu-programi" component={homeERP2} />

              <ScrollToTopRoute path="/hakkimizda" component={About} />
              <ScrollToTopRoute path="/ozellikler" component={Service} />

              <ScrollToTopRoute path="/iletisim" component={Contact} />
              <ScrollToTopRoute path="/ucretsiz-kullan" component={DemoRequests} />
              <ScrollToTopRoute path="/teklif-iste" component={BuyRequests} />

              <ScrollToTopRoute path="/paketler" component={Price} />
              <ScrollToTopRoute path="/soru-cevap" component={Faq} />

              <ScrollToTopRoute path="/randevu-takibi" component={RandevuTakibi} />
              <ScrollToTopRoute path="/randevu-takip-programi" component={RandevuTakibi} />
              <ScrollToTopRoute path="/randevu-programi" component={RandevuTakibi} />
              <ScrollToTopRoute path="/ucretsiz-randevu-programi" component={RandevuTakibi} />

              <ScrollToTopRoute path="/gelir-gider-takibi" component={GelirGiderTakibi} />
              <ScrollToTopRoute path="/zamandan-tasarruf" component={ZamandanTasarruf} />
              <ScrollToTopRoute path="/veri-guvenligi" component={VeriGuvenligi} />
              <ScrollToTopRoute path="/esnek-yetkilendirme" component={EsnekYetkilendirme} />
              <ScrollToTopRoute path="/destek-sistemi" component={DestekSistemi} />
              <ScrollToTopRoute path="/kurulum-gerektirmez" component={KurulumGerektirmez} />
              <ScrollToTopRoute path="/kullanıcı-dostu-arayuz" component={KullanıcıDostuArayuz} />
              <ScrollToTopRoute path="/versiyon-guncelleme" component={VersiyonGuncelleme} />
              <ScrollToTopRoute path="/sms-entegrasyonu" component={SmsEntegrasyonu} />
              <ScrollToTopRoute path="/toplu-sms-gonderimi" component={SmsEntegrasyonu} />

              <ScrollToTopRoute path="/e-posta-entegrasyonu" component={EpostaEntegrasyonu} />
              <ScrollToTopRoute path="/sube-yonetimi" component={SubeYonetimi} />
              <ScrollToTopRoute path="/coklu-dil-secenegi" component={CokluDilSecenegi} />
              <ScrollToTopRoute path="/guvenli-erisim" component={GuvenliErisim} />
              <ScrollToTopRoute path="/iletisim-formu-entegrasyonu" component={IletisimFormu} />
              <ScrollToTopRoute path="/musteri-filtreleme" component={MusteriFiltreleme} />
              <ScrollToTopRoute path="/musteri-memnuniyet-anketi" component={MusteriMemnuniyetAnketi} />
              <ScrollToTopRoute path="/sosyal-medya-entegrasyonu" component={SosyalMedyaEntegrasyonu} />
              <ScrollToTopRoute path="/urun-takibi" component={UrunTakibi} />
              <ScrollToTopRoute path="/personel-performans-raporlama" component={PersonelPerformansRaporlama} />
              <ScrollToTopRoute path="/e-fatura" component={Efatura} />
              <ScrollToTopRoute path="/parapuan" component={ParaPuan} />
              <ScrollToTopRoute path="/veri-aktarimi" component={VeriAktarimi} />
              <ScrollToTopRoute path="/seansli-satis" component={SeansliSatis} />
              <ScrollToTopRoute path="/kvkk" component={KVKK} />
              <ScrollToTopRoute path="/acik-riza-metni" component={AcikRizaMetni} />
              <ScrollToTopRoute path="/blog" component={BlogList} />
              
              <ScrollToTopRoute path="/salon-uretkenligi" component={SalonUretkenligi} />



              <ScrollToTopRoute path="/" component={homeERP} />

              <ScrollToTopRoute component={NotFound} />


            </Switch>
          </Suspense>
        </Router>
      </>
    );
  }
}

export default App;
