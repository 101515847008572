import React from 'react';
import Lottie from 'react-lottie';
import LoadingLottie from './beauty-loading.json';
import './style.css';

function LoadingSpinner(props) {
    return (
        <div className="backdrop-loading">
            <Lottie
                options={{ loop: true, autoplay: true, animationData: LoadingLottie }}
                isClickToPauseDisabled
                height={120}
                width={120}
            />
        </div>
    );
}

export default LoadingSpinner;